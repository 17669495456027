<div [hidden]="tenderEntityService.tenderId$ | async" id="mb-container">
  <div class="px-3">
    <div class="mb-title">
      {{'tenders.board.title' | translate}}
    </div>
    <div class="d-flex flex-column gap-2">
      <div class="filters"
      [style.height.px]="showFilters ? 72 : 32">
        <div class="filters-column">
          <div class="d-flex align-items-center gap-2">
            <app-filter-status [tendersSearchService]="tendersSearchService"
                               [items]="status"/>
            <app-filter-assignment [tendersSearchService]="tendersSearchService"/>
            <div class="flex-center-center vr"></div>
            <div class="flex-center-center toggle-filters text-truncate no-select" (click)="showFilters = !showFilters">
              {{ (showFilters ? 'tenders.board.less-filters' : 'tenders.board.more-filters') | translate }}
            </div>
          </div>
          <div class="tenders-filters" [hidden]="!showFilters">
            <app-tenders-filter
              [tenderSearchService]="tendersSearchService"
              [filters]="[FilterType.TERRITORY, FilterType.TYPE]"
              (filterChange)="onFilterChange($event)"
            />
          </div>
        </div>
      </div>
      <div class="d-flex gap-3 align-items-center">
        <app-filter-date [tenderSearchService]="tendersSearchService"
                         (periodSelected)="periodSelected($event)"
                         [defaultPeriod]="defaultPeriod"/>
        <app-tenders-summary [tendersSearchService]="tendersSearchService"
                             noPeriodDisplayed
                             class="flex-grow-1"/>
          <ex-filter-text [placeholder]="'tenders.filter-results' | translate"
                          [text]="tendersSearchService.refinedSearchText"
                          (textChange)="onRefineResults($event)"/>
          <app-tenders-sort [tendersSearchService]="tendersSearchService"
                            [sortFields]="[SortFieldEnum.INDEXATION_DATE, SortFieldEnum.CLOSING_DATE, SortFieldEnum.ESTIMATED_END_DATE]"
                            defaultSort="closing_date"
                            inBoardView/>
        <app-tenders-action-toggle [disabled]="!tendersSearchService.tenders?.length" />
      </div>
    </div>
  </div>
  <app-tenders-list [tendersSearchService]="tendersSearchService" [tendersExportService]="tendersExportService"
                    inBoardView/>
  <app-tenders-action-bar [tendersSearchService]="tendersSearchService"/>
</div>
<router-outlet></router-outlet>
