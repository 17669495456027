import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TendersActionService } from '../../services/tenders-action.service';
import { TemplatePortal } from '@angular/cdk/portal';
import { TendersSearchService } from '../../services/tenders-search.service';
import { TenderEntityService } from '../../services/tender-entity.service';
import { Subscription } from 'rxjs';
import { TendersModuleService } from '../../services/tenders-module.service';
import { DropdownItem } from '../../../common-explain/components/ex-dropdown/ex-dropdown.component';
import { StatusItem } from '../../models/tender-status';
import { ModalConfirmationWithCheckbox } from '../../../components/modal-confirmation-with-checkbox/modal-confirmation-with-checkbox';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tenders-action-bar',
  templateUrl: './tenders-action-bar.component.html',
  styleUrls: ['./tenders-action-bar.component.scss']
})
export class TendersActionBarComponent implements AfterViewInit, OnChanges, OnDestroy {
  protected NO_CONFIRMATION_COUNT_THRESHOLD = 11;
  protected TENDERS_EXPORT_LIMIT = 1000;
  protected TENDERS_TAG_LIMIT = 300;


  @Input({required: true}) tendersSearchService!: TendersSearchService;

  @ViewChild('actionBar') actionBar!: TemplateRef<any>;
  overlayRef!: OverlayRef;
  serviceListener!: Subscription;
  targetAction ?: 'export' | 'tag';
  openTagAction ?: 'selection' | 'all';

  constructor(private overlay: Overlay,
              private viewContainerRef: ViewContainerRef,
              protected tendersActionService: TendersActionService,
              protected tenderEntityService: TenderEntityService,
              protected tendersModuleService: TendersModuleService,
              private modalService: NgbModal,
  ) { }

  ngAfterViewInit() {
    const positionStrategy = this.overlay.position().global().centerHorizontally().bottom("64px");
    const overlayConfig = new OverlayConfig({
      positionStrategy
    })
    this.overlayRef = this.overlay.create(overlayConfig);
    this.overlayRef.attach(new TemplatePortal(this.actionBar, this.viewContainerRef));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tendersSearchService']) {
      if (this.serviceListener) this.serviceListener.unsubscribe();
      this.serviceListener = this.tendersSearchService._filters$.subscribe((filters) => {
        if (!filters?.offset && this.tendersActionService.selection) this.tendersActionService.selection = [];
      })
    }
  }

  ngOnDestroy() {
    this.serviceListener.unsubscribe();
  }

  async statusItemSelect(item: DropdownItem<StatusItem> | null) {
    if (!item) return;
    const tagAction = this.openTagAction;
    const targetCount =
      tagAction === 'selection' ? this.tendersActionService.selection?.length ?? 0 : this.tendersSearchService.totalCount;
    if (targetCount < this.NO_CONFIRMATION_COUNT_THRESHOLD) {
      await this.tendersActionService.tag(
        tagAction === 'selection' ? this.tendersActionService.selection ?? [] : this.tendersSearchService.tenders!,
        item.value,
        this.tendersSearchService
      );
    } else {
      const modal = this.modalService.open(
        ModalConfirmationWithCheckbox, {centered: true, windowClass: 'modal-width-560'}
      );
      const tendersToTag = tagAction === 'all' &&
        this.tendersActionService.retrieveRemainingTenders(this.tendersSearchService, this.TENDERS_TAG_LIMIT);
      modal.componentInstance.deleteMode = true;
      modal.componentInstance.translationFileSection = 'tenders.action';
      modal.componentInstance.affectedItemsCount = targetCount;
      modal.componentInstance.onValidateAction = async () => {
        await this.tendersActionService.tag(
          tendersToTag ? await tendersToTag : this.tendersActionService.selection ?? [], item.value, this.tendersSearchService
        );
      };

    }
    this.openTagAction = undefined;
    this.targetAction = undefined;
  }
}
